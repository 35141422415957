<template>
  <div class='container pb60'>
    <!-- 核心团队 -->
    <aboutUsBorder :title="title" :content='content'></aboutUsBorder>
    <!-- //核心团队 -->

    <!-- 董事长 -->
    <div class="h720 mt120 flex">
      <div>
        <div class="y_box"></div>
        <div class="img_box w455 h667">
          <img src="@/assets/images/aboutUs/chairman.png" class="w-max h-max">
        </div>
      </div>
      <div class="mt53 ml119">
        <div class="fs32 fw-600">王涛 董事长</div>
        <div class="fs24 color9D mt40 lh45">
          毕业于北京理工大学，曾供职于北京工商银行，北京瀚达医药有限公司，任北京国药中北器械有限公司副总经理后，成功创办迈世通（北京）医疗器械有限公司，日记本（北京）健康科技有限公司。在医疗领域有着丰富的销售、渠道及产品研发资源。成功发起公益基金“新起点基金—金种子计划”并成为50个发起投资人之一，被授予“金种子天使合伙人”的荣誉称号。2021年王涛先生获得中国国家标准化管理委员会评级颁发的“诚信企业家”称号。
        </div>
      </div>
    </div>
    <!-- //董事长 -->

    <div class="mt120 flex flex-wrap">
      <div class="w445 mb60 mr190 team_item pointer" v-for="(item,i) in teamList" :key="i" @click="btnTeam(item)">
        <img :src="item.pcPic" class="w445 h500" >
        <div class="text-center mt40 fs32 fw-600">{{item.memberName}}</div>
        <div class="text-center mt10 color9D fs24 lh45">{{item.memberPosition}}</div>
      </div>
    </div>
    <userInfoDiallog :info='info' :dialogVisible.sync="dialogVisible"></userInfoDiallog>
  </div>
</template>

<script>
import aboutUsBorder from '@/components/aboutUsBorder.vue'
import userInfoDiallog from '@/components/userInfoDialog.vue'
import { getTeam } from '@/api'
export default {
  data () {
    return {
      title: '核心团队',
      content: '公司核心团队介绍如下',
      teamList: [],
      info: {},
      dialogVisible: false
    };
  },

  components: { aboutUsBorder, userInfoDiallog },

  created () {
    this.$store.commit('editAboutRouterName', this.$route.meta.title)
    //获取团队成员列表
    this.getTeam()
  },

  mounted () { },

  methods: {
    //获取团队成员列表
    async getTeam () {
      const res = await getTeam()
      this.teamList = res.data
    },
    //点击成员
    btnTeam (item) {
      this.info = item
      this.dialogVisible = true
    }
  }
}
</script>
<style lang='scss' scoped>
.img_box {
  z-index: 999;
  margin-top: -107px;
}

.y_box {
  margin-left: 178px;
  width: 318px;
  height: 160px;
  background: rgba(247, 181, 0, 0.24);
}

.team_item:nth-child(3n + 0) {
  margin-right: 0 !important;
}
</style>
